import {HIDE_FILTER} from "./hideFilterType";

const initialState = true;

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case HIDE_FILTER:
            return !state
        default:
            return state
    }
}

export default reducer;
