import Select from "react-select";
import Slider from "rc-slider";
import {Link} from "react-router-dom";
import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {clearRoutes, findRoutes} from "../redux/findRoutes/findRoutesAction";
import {getCountriesName} from "../redux/countriesName/countriesNameAction";
import {clearSelectedFilters, setSelectedFilters} from "../redux/selectedFilters/selectedFiltersAction";

const Filter = ({style}) => {
    const countiesNameData = useSelector(state => state.countiesName);
    const selectedFiltersData = useSelector(state => state.selectedFilters);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getCountriesName());
    }, [])

    const handleSearch = () => {
        dispatch(clearRoutes());
        let config = {
            countries: selectedFiltersData.countries.map(x => x.value),
            stops: Number(selectedFiltersData.stops),
            popularity: selectedFiltersData.popularity.map(x => x.value),
            priceLess: selectedFiltersData.price[1],
            priceMore: selectedFiltersData.price[0],
            tourismType: selectedFiltersData.tourismType.map(x => x.value),
            seasons: selectedFiltersData.seasons.map(x => x.value)
        };
        dispatch(findRoutes(config));
    }

    return (
        <div className={`${style.panel} ${style.hide}`}>
            <div className="col-span-2 filter">
                <div className={style.name}>Country:</div>
                <Select
                    maxMenuHeight={168}
                    defaultValue={selectedFiltersData.countries}
                    onChange={data => dispatch(setSelectedFilters(
                        {...selectedFiltersData, countries: data}))}
                    options={countiesNameData.names}
                    isMulti
                    isSearchable
                    placeholder="Any"
                />
            </div>
            <div className="col-span-2 filter">
                <div className={style.name}>Price per day:</div>
                <div className={`flex justify-between ${style.name}`}>
                    <div>${selectedFiltersData.price[0]}</div>
                    <div>${selectedFiltersData.price[1]}</div>
                </div>
                <Slider
                    range
                    defaultValue={selectedFiltersData.price}
                    onAfterChange={data => dispatch(setSelectedFilters(
                        {...selectedFiltersData, price: data}))}
                    min={0}
                    max={500}
                    trackStyle={{backgroundColor: "#570df8"}}
                    handleStyle={{backgroundColor: "white"}}
                />
            </div>
            <div className="col-span-2 filter">
                <div className={style.name}>Season:</div>
                <Select
                    defaultValue={selectedFiltersData.seasons}
                    onChange={data => dispatch(setSelectedFilters(
                        {...selectedFiltersData, seasons: data}))}
                    options={[
                        {value: "Winter", label: "Winter"},
                        {value: "Spring", label: "Spring"},
                        {value: "Summer", label: "Summer"},
                        {value: "Fall", label: "Fall"}
                    ]}
                    isMulti
                    placeholder="Any"
                />
            </div>
            <div className="col-span-2 flex flex-col gap-y-2">
                <div className={style.name}>Tourism type:</div>
                <Select
                    defaultValue={selectedFiltersData.tourismType}
                    onChange={data => dispatch(setSelectedFilters(
                        {...selectedFiltersData, tourismType: data}))}
                    options={[
                        {value: "Adventure", label: "Adventure"},
                        {value: "Culture", label: "Culture"},
                        {value: "Beach", label: "Beach"},
                        {value: "Wildlife", label: "Wildlife"}
                    ]}
                    isMulti
                    placeholder="Any"
                />
            </div>
            <div className="col-span-2 xl:col-span-1 filter">
                <div className={style.name}>Popularity:</div>
                <Select
                    defaultValue={selectedFiltersData.popularity}
                    maxMenuHeight={168}
                    onChange={data => dispatch(setSelectedFilters(
                        {...selectedFiltersData, popularity: data}))}
                    options={[
                        {value: 5, label: 5},
                        {value: 4, label: 4},
                        {value: 3, label: 3},
                        {value: 2, label: 2},
                        {value: 1, label: 1}
                    ]}
                    isMulti
                    placeholder="Any"
                />
            </div>
            <div className="col-span-2 xl:col-span-1 filter">
                <div className={style.name}>Stops:</div>
                <input type="number"
                       min={1}
                       max={7}
                       value={selectedFiltersData.stops}
                       onChange={data => dispatch(setSelectedFilters(
                           {...selectedFiltersData, stops: data.target.value > 7 ? 7 : data.target.value}))}
                       className="input input-bordered w-full h-10 bg-white"/>
            </div>
            <Link to="/find-routes" className="btn bg-primary col-span-2 text-white"
                  onClick={() => handleSearch()}>
                Search
            </Link>
        </div>
    )
}

export default Filter;
