const HomeAbout = () => {
    return (
        <div className="hero pattern-leaves" id="about">
            <div className="hero-content flex flex-col lg:flex-row w-11/12 h-screen">
                <div className="text-primary-content w-full lg:w-3/5">
                    <h1 className="mb-3 text-5xl lg:text-9xl logo font-light text-center lite-search-h1">About us</h1>
                    <p className="mb-3 text-lg lg:text-3xl text-center lite-search-p">
                        Travbase is a service which provides tourists
                        with different routes depending on their search.
                        Just specify what you want and
                        our algorithms will find the best suitable options for you.
                        We have a big database of countries and cities so you won`t be bored.
                    </p>
                    <p className="mb-3 text-md lg:text-xl text-center lite-search-p font-bold">
                        If you have any questions or want
                        to contact us feel free to use this email:
                        <br/>
                        <a href="mailto: artur.onopriichuk@gmail.com">artur.onopriichuk@gmail.com</a>
                    </p>
                </div>
            </div>
        </div>
    )
}

export default HomeAbout;
