const CountryCard = ({country}) => {
    return (
        <div className="card w-full md:w-96 shrink-0 bg-white text-black">
            <div className="h-48 w-full p-3">
                <img src={country.image}
                     alt="city"
                     className="w-full h-full object-cover rounded-xl"/>
            </div>
            <div className="p-3 flex flex-col gap-3 justify-center">
                <div className="text-lg xl:text-2xl"><b>{country.name}</b>, {country.continent}</div>
                <div className="text-sm xl:text-lg">{country.info}</div>
            </div>
        </div>
    );
};

export default CountryCard;