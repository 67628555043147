import {FIND_ROUTES_SUCCESS, FIND_ROUTES_FAILURE, CLEAR_ROUTES} from "./findRoutesType";

const initialState = {
    loading: true,
    routes: [],
    error: ""
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case FIND_ROUTES_SUCCESS:
            return {
                loading: false,
                routes: action.payload,
                error: ""
            }
        case FIND_ROUTES_FAILURE:
            return {
                loading: false,
                routes: [],
                error: action.payload
            }
        case CLEAR_ROUTES:
            return initialState
        default:
            return state
    }
}

export default reducer;
