import {Navigate, Route, Routes, useLocation} from "react-router-dom";
import FindRoutes from "./FindRoutes/FindRoutes";
import RouteInfo from "./Route/RouteInfo";
import Home from "./Home/Home";

function App() {
    const location = useLocation();

    return (
        <div className="App">
            <Routes>
                <Route path="/" element={<Home/>}/>
                <Route path="/find-routes" element={<FindRoutes/>}/>
                <Route path="/route" element={<RouteInfo route={location.state}/>}/>
                <Route path="*" element={<Navigate to="/"/>}/>
            </Routes>
        </div>
    );
}

export default App;
