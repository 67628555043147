const HomeStart = () => {
    return (
        <div className="hero h-screen pattern-waves" id="home">
            <div className="hero-content flex flex-col">
                <div className="text-primary-content self-center">
                    <h1 className="mb-5 text-6xl lg:text-9xl logo font-light text-center lite-search-h1">travbase</h1>
                    <p className="mb-5 text-xl lg:text-3xl text-center lite-search-p">
                        Brings joy to your trip
                    </p>
                </div>
                <a className="mt-2" href="#search">
                    <div className="animate-bounce cursor-pointer
                    bg-primary-content text-primary p-5 rounded-full
                    flex items-center justify-center
                    hover:bg-neutral hover:text-primary-content">
                        <svg className="w-8 h-8" fill="none"
                             strokeLinecap="round"
                             strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                            <path d="M19 14l-7 7m0 0l-7-7m7 7V3"/>
                        </svg>
                    </div>
                </a>
            </div>
        </div>
    )
}

export default HomeStart;
