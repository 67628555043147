import {CLEAR_ROUTES, FIND_ROUTES_FAILURE, FIND_ROUTES_SUCCESS} from "./findRoutesType";
import axios from "axios";

export const getRoutesSuccess = routes => {
    return {
        type: FIND_ROUTES_SUCCESS,
        payload: routes
    }
}

export const getRoutesFailure = error => {
    return {
        type: FIND_ROUTES_FAILURE,
        payload: error
    }
}

export const clearRoutes = () => {
    return {
        type: CLEAR_ROUTES
    }
}

export const findRoutes = config => {
    return (dispatch) => {
        axios.post(`https://travbase-server.vercel.app/filter`, config, {
            headers: {"Content-Type": "application/json"}
        }).then(res => {
            if (res.data.message) throw res.data;
            dispatch(getRoutesSuccess(res.data));
        }).catch(error => {
            dispatch(getRoutesFailure(error.message));
        });
    }
}