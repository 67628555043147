import {combineReducers} from "redux";
import findRoutesReducer from "./findRoutes/findRoutesReducer";
import countriesNameReducer from "./countriesName/countriesNameReducer";
import selectedFiltersReducer from "./selectedFilters/selectedFiltersReducer";
import hideFilterReducer from "./hideFilter/hideFilterReducer";

const rootReducer = combineReducers({
    findRoutes: findRoutesReducer,
    countiesName: countriesNameReducer,
    selectedFilters: selectedFiltersReducer,
    hideFilter: hideFilterReducer
})

export default rootReducer;