import Filter from "../Filter";

const HomeSearch = () => {
    return (
        <div className="hero pattern-wiggle" id="search">
            <div className="hero-content flex flex-col lg:flex-row w-11/12 h-max lg:h-screen">
                <div className="text-primary-content w-full lg:w-3/5 flex flex-col items-center">
                    <h1 className="mb-3 text-5xl lg:text-9xl logo font-light text-center lite-search-h1">Search</h1>
                    <p className="mb-3 text-lg lg:text-3xl text-center w-5/6 lite-search-p">
                        Search whatever you want. Our system will find you the best matching routes!
                    </p>
                </div>
                <Filter style={{panel: "filters-home", name: "filter-name-home"}}/>
            </div>
        </div>
    )
}

export default HomeSearch;
