import {SET_SELECTED_FILTERS, CLEAR_SELECTED_FILTERS} from "./selectedFiltersType";

const initialState = {
    price: [0, 500],
    countries: [],
    seasons: [],
    popularity: [],
    tourismType: [],
    stops: 2
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SELECTED_FILTERS:
            return action.payload
        case CLEAR_SELECTED_FILTERS:
            return initialState
        default:
            return state
    }
}

export default reducer;
