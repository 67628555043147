import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.css';
import App from './components/App';
import {BrowserRouter} from "react-router-dom";
import 'rc-slider/assets/index.css'
import {Provider} from "react-redux";
import store from "./redux/store";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<BrowserRouter><Provider store={store}><App/></Provider></BrowserRouter>);
