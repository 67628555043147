import {COUNTRIES_NAME_SUCCESS, CLEAR_NAME_COUNTRIES} from "./countriesNameType";
import axios from "axios";

export const getCountriesNameSuccess = names => {
    return {
        type: COUNTRIES_NAME_SUCCESS,
        payload: names
    }
}

export const clearCountriesName = () => {
    return {
        type: CLEAR_NAME_COUNTRIES
    }
}

export const getCountriesName = () => {
    return (dispatch) => {
        axios.get(`https://travbase-server.vercel.app/countries`, {
            headers: {"Content-Type": "application/json"}
        })
            .then(res => dispatch(getCountriesNameSuccess(res.data
                .map(country => country.name)
                .sort()
                .map(countryName => {
                    let object = {};
                    object.value = countryName;
                    object.label = countryName;
                    return object;
                }))));
    }
}