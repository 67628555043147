const CityCard = ({city}) => {

    const popularity = number => {
        switch (number) {
            case 5:
                return (<>
                    <img src="images/star.svg" className="w-7 h-7" alt="star"/>
                    <img src="images/star.svg" className="w-7 h-7" alt="star"/>
                    <img src="images/star.svg" className="w-7 h-7" alt="star"/>
                    <img src="images/star.svg" className="w-7 h-7" alt="star"/>
                    <img src="images/star.svg" className="w-7 h-7" alt="star"/>
                </>)
            case 4:
                return (<>
                    <img src="images/star.svg" className="w-7 h-7" alt="star"/>
                    <img src="images/star.svg" className="w-7 h-7" alt="star"/>
                    <img src="images/star.svg" className="w-7 h-7" alt="star"/>
                    <img src="images/star.svg" className="w-7 h-7" alt="star"/>
                </>)
            case 3:
                return (<>
                    <img src="images/star.svg" className="w-7 h-7" alt="star"/>
                    <img src="images/star.svg" className="w-7 h-7" alt="star"/>
                    <img src="images/star.svg" className="w-7 h-7" alt="star"/>
                </>)
            case 2:
                return (<>
                    <img src="images/star.svg" className="w-7 h-7" alt="star"/>
                    <img src="images/star.svg" className="w-7 h-7" alt="star"/>
                </>)
            case 1:
                return (<>
                    <img src="images/star.svg" className="w-7 h-7" alt="star"/>
                </>)
        }
    }

    return (
        <div
            className="card lg:h-fit w-full
            flex xl:flex-row lg:items-center">
            <div className="w-full h-80 xl:w-96 p-3 shrink-0">
                <img src={city.image}
                     alt="city"
                     className="w-full h-full object-cover rounded-xl"/>
            </div>
            <div className="h-full p-3 flex flex-col gap-3 justify-center text-lg xl:text-2xl">
                <div className="flex flex-row gap-x-2 items-center flex-wrap">
                    <img src="images/globe.svg" className="w-8 h-8" alt="country"/>
                    <div><b>{city.name}</b>, {city.country}</div>
                </div>
                <div className="flex flex-row gap-x-2 items-center flex-wrap">
                    <img src="images/money.svg" className="w-8 h-8" alt="money"/>
                    Average price/day: {city.pricePerDay}$
                </div>
                <div className="flex flex-row gap-x-2 items-center flex-wrap">
                    <img src="images/people.svg" className="w-8 h-8" alt="people"/>
                    <div>Popularity:</div>
                    <div className="flex sm:gap-2">{popularity(city.popularity)}</div>
                </div>
                <div className="flex flex-row gap-x-2 items-center flex-wrap">
                    <img src="images/calendar.svg" className="w-8 h-8" alt="calendar"/>
                    Seasons: {city.seasons.map(season => <div key={season}>{season}</div>)}
                </div>
                <div className="flex flex-row gap-x-2 items-center flex-wrap">
                    <img src="images/tourism.svg" className="w-8 h-8" alt="tourism type"/>
                    Tourism: {city.tourismType.map(type => <div key={type}>{type}</div>)}
                </div>
                <div className="w-fit xl:text-lg">
                    {city.info}
                </div>
            </div>
            <div className="flex flex-col gap-3 xl:gap-5 w-full mb-3 xl:w-52 xl:ml-auto xl:mr-3 shrink-0">
                <a className="btn text-primary-content flex gap-2 mx-3 xl:mx-0"
                   href={`https://www.booking.com/searchresults.html?ss=${city.name}`} target="_blank">
                    <img src="images/point.svg" className="w-6 h-6" alt="point"/>
                    <div>Hotels on Booking</div>
                </a>
            </div>
        </div>
    )
};

export default CityCard;