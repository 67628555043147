import {SET_SELECTED_FILTERS, CLEAR_SELECTED_FILTERS} from "./selectedFiltersType";

export const setSelectedFilters = selectedFilters => {
    return {
        type: SET_SELECTED_FILTERS,
        payload: selectedFilters
    }
}

export const clearSelectedFilters = () => {
    return {
        type: CLEAR_SELECTED_FILTERS
    }
}