const HomeNavbar = () => {
    return (
        <div className="navbar z-10
        bg-primary text-primary-content
        invisible lg:visible
        fixed
        justify-between">
            <div className="">
                <a href="/" className="btn btn-ghost
                normal-case font-light logo
                text-2xl xl:text-4xl">travbase</a>
            </div>
            <div className="">
                <ul className="menu menu-horizontal p-0">
                    <li><a href="#home" className="text-xl xl:text-2xl">Home</a></li>
                    <li><a href="#search" className="text-xl xl:text-2xl">Search</a></li>
                    <li><a href="#about" className="text-xl xl:text-2xl">About us</a></li>
                </ul>
            </div>
        </div>
    )
}

export default HomeNavbar;
