import {useSelector} from "react-redux";
import Filter from "../Filter";
import FindRoutesNavbar from "./FindRoutesNavbar";
import RouteCard from "./RouteCard";

const FindRoutes = () => {
    const findRoutesData = useSelector(state => state.findRoutes)
    const hideFilterData = useSelector(state => state.hideFilter)

    return (
        <div className="pattern-waves min-h-screen pb-10">
            <FindRoutesNavbar/>
            <div className="flex flex-col lg:flex-row w-11/12 mx-auto mt-12 gap-y-10">
                <Filter
                    style={{panel: "filter-routes", name: "filter-name-routes", hide: hideFilterData ? "hidden" : ""}}/>
                {findRoutesData.loading ? (
                        <div className={`flex flex-col 
                        ${!hideFilterData ? "lg:w-3/4" : "w-full"}
                         items-center justify-center`}>
                            <div className="text-xl xl:text-2xl text-white font-bold">
                                It may take some time...
                            </div>
                            <div className="loader">Loading...</div>
                        </div>
                    ) :
                    findRoutesData.error ?
                        <div
                            className={`flex flex-col
                            ${!hideFilterData ? "lg:w-3/4" : "w-full"}
                            items-center justify-center text-xl xl:text-2xl text-white font-bold`}>
                            {findRoutesData.error}
                        </div> :
                        <div id="routes"
                             className={`flex flex-col
                                        w-full h-min
                                        lg:w-3/4
                                        lg:px-10 gap-10 items-center
                                        ${!hideFilterData ? "hidden lg:flex" : "lg:w-full"}`}>
                            {findRoutesData.routes.map(route => {
                                return <RouteCard key={route.distance} route={route}/>
                            })}
                        </div>}
            </div>
        </div>
    )
}

export default FindRoutes;