import {Link} from "react-router-dom";

const RouteCard = ({route}) => {

    const mapLink = route => route.cities.map(city => `${city.name},+${city.country}`).join("/");

    return (
        <div
            className="card lg:h-52 w-full
            bg-white shadow-lg shadow-primary
            flex lg:flex-row lg:items-center">
            <div className="w-full h-80 lg:w-80 lg:h-full p-3">
                <img src={route.cities[0].image}
                     alt="city"
                     className="w-full h-full object-cover rounded-xl"/>
            </div>
            <div className="h-full p-3 flex flex-col gap-3 justify-center text-lg xl:text-2xl font-bold text-black">
                <div className="flex flex-row gap-x-2 items-center flex-wrap">
                    <img src="images/globe.svg" className="w-8 h-8" alt="country"/>
                    {route.countries.map(country => <div>{country.name}</div>)}
                </div>
                <div className="flex flex-row gap-x-2 items-center flex-wrap">
                    <img src="images/city.svg" className="w-8 h-8" alt="city"/>
                    {route.cities.map(city => <div>{city.name}</div>)}
                </div>
                {route.distance ?
                    <div className="flex flex-row gap-x-2 w-max items-center">
                        <img src="images/distance.svg" className="w-8 h-8" alt="distance"/>
                        <div>
                            {route.distance}km
                        </div>
                    </div>
                    : null}
            </div>
            <div className="flex flex-col gap-3 lg:gap-5 w-full mb-3 lg:w-52 lg:ml-auto lg:mr-10">
                <a className="btn text-primary-content flex gap-2 mx-3 lg:mx-0"
                   href={`https://www.google.com/maps/dir/${mapLink(route)}`} target="_blank">
                    <img src="images/point.svg" className="w-6 h-6" alt="point"/>
                    <div>Maps</div>
                </a>
                <Link to="/route" state={route} className="btn btn-primary mx-3 lg:mx-0">
                    More
                </Link>
            </div>
        </div>
    )
}

export default RouteCard;
