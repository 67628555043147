import {useDispatch} from "react-redux";
import {hideFilter} from "../../redux/hideFilter/hideFilterAction";
import {Link} from "react-router-dom";

const FindRoutesNavbar = () => {
    const dispatch = useDispatch();

    return (
        <div className="navbar z-10
        bg-primary text-primary-content
        fixed sticky top-0
        justify-between">
            <div>
                <Link to="/" className="btn btn-ghost
                normal-case font-light logo
                text-2xl xl:text-4xl">travbase</Link>
            </div>
            <button className="btn btn-active bg-white btn-square"
                    onClick={() => dispatch(hideFilter())}>
                <img src="images/filter.svg" className="w-6 h-6" alt="filter"/>
            </button>
        </div>
    )
}

export default FindRoutesNavbar;
