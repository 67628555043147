import CountryCard from "./CountryCard";
import RouteNavbar from "./RouteNavbar";
import CityCard from "./CityCard";

const RouteInfo = ({route}) => {

    const mapLink = route => route.cities.map(city => `${city.name},+${city.country}`).join("/");

    return (
        <div className="pattern-waves min-h-screen pb-10">
            <RouteNavbar/>
            <div className="flex mx-auto mt-12 gap-10 w-5/6 overflow-x-auto">
                {route.countries.map(country => <CountryCard key={country._id} country={country}/>)}
            </div>
            <div
                className="flex flex-col w-5/6 bg-white shadow-lg shadow-primary rounded-xl mx-auto mt-12 text-black">
                <div className="mt-5 flex flex-row gap-x-2 w-full items-end justify-center">
                    <div className="text-3xl xl:text-5xl">Your route</div>
                    <img src="images/distance.svg" className="w-8 h-8 xl:w-10 xl:h-10" alt="distance"/>
                    <div className="text-xl xl:text-3xl">({route.distance}km)</div>
                </div>
                <div className="flex flex-col gap-10 mt-3">
                    {route.cities.map(city => <CityCard key={city._id} city={city}/>)}
                </div>
                <div className="flex flex-col gap-3 w-full my-5">
                    <a className="btn text-primary-content flex gap-2 mx-3 bg-primary"
                       href={`https://www.google.com/maps/dir/${mapLink(route)}`} target="_blank">
                        <img src="images/point.svg" className="w-6 h-6" alt="point"/>
                        <div>View on Google Maps</div>
                    </a>
                </div>
            </div>
        </div>
    );
};

export default RouteInfo;