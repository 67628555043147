import {Link} from "react-router-dom";

const RouteNavbar = () => {
    return (
        <div className="navbar z-10
        bg-primary text-primary-content
        fixed sticky top-0
        justify-between">
            <div>
                <Link to="/" className="btn btn-ghost
                normal-case font-light logo
                text-2xl xl:text-4xl">travbase</Link>
            </div>
        </div>
    )
};

export default RouteNavbar;