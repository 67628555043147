import {COUNTRIES_NAME_SUCCESS, CLEAR_NAME_COUNTRIES} from "./countriesNameType";

const initialState = {
    loading: true,
    names: []
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case COUNTRIES_NAME_SUCCESS:
            return {
                loading: false,
                names: action.payload,
                error: ""
            }
        case CLEAR_NAME_COUNTRIES:
            return initialState
        default:
            return state
    }
}

export default reducer;
