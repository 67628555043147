import HomeNavbar from "./HomeNavbar";
import HomeStart from "./HomeStart";
import HomeSearch from "./HomeSearch";
import HomeAbout from "./HomeAbout";

const Home = () => {

    return (
        <div className="my-container">
            <HomeNavbar/>
            <HomeStart/>
            <HomeSearch/>
            <HomeAbout/>
        </div>
    )
}

export default Home;